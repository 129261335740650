.table-row:hover {
    cursor: pointer;
}

.min-width-188 {
    min-width: 188px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    text-align: left;
}

.pagination h1 {
    font-size: 1.063rem !important;
    font-weight: 300;
    line-height: 2.5em !important;
}
