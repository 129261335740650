.outter-box {
    display: flex;
    flex-wrap: wrap;
    text-align:center;
    min-height:500px;
}

.instructions {
    max-width: 350px;
    padding: 15px;
    text-align: center;
    min-width: 300px;
    margin: auto;
}

.instructions a>p {
    display:inline-block; 
    margin:0 10px;
}

div.instructions>img {
    width:200px;
}