.driver-checkin {
    min-height: 460px;
}

.h1 {
    text-align: center;
}

.load-wrapper {
    text-align: left;
    max-width: 350px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.checkin {
    width: 20px;
    height: 15px;
    margin-bottom: 15px !important;
}

.checkout {
    width: 80px;
    height: 15px;
    margin-bottom: 15px !important;
}

.li {
    max-width: 350px;
}

.label {
    width: 120px;
    margin-bottom: 10px;
    margin-right: -20px !important;
}