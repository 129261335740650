/* 
* * Common css module, where shared styles go
*/

.rule {
    margin-top: 10px;
    border-bottom: 1px solid black;
    max-width: 300px
}

h1, h2, h3, h3 {
    color: #3c4858 !important;
}

.primary-button {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(30 56 233 / 40%) !important;
    background-color: #001933 !important;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    color: rgba(255,255,255,0.5);
}