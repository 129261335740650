h1 {
    margin-top: 0;
}

.pdf-grid > div{
    margin-top: 10px;
}

.info-wrapper {
    max-height: 800px;
    overflow: scroll;
}

.info-wrapper > h1, .info-wrapper > h3 {
    margin: 0;
}

.grid-item {
    text-align: center;
}

.osd {
    color: #3c4858 !important;
}

.osd-button {
    display: block !important;
    margin: auto !important;
}
