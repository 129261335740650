
.change-password {
    font-size: 1.063rem !important;
    font-weight: 300;
    line-height: 2.5em !important;
}


.submit-button, .reset-button {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(30 56 233 / 40%) !important;
    background-color: #001933 !important;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    color: rgba(255,255,255,0.5);
}

.grid-container {
    min-height: 540px
}

.text-align {
    text-align: center;
}

.margin {
    margin: 0 30px !important;
}

.max-width {
    max-width: 346px;
}

.instruction {
    margin: 20px 0;
    max-width: 300px;
}
