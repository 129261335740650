img.driver-photo {
    width: 100%;
}

.truck-company-info {
    max-width:300px;
}

.driver-info-box {
    text-align:center;
    margin-bottom:50px
}

.remove-img-button {
    margin-bottom:50px;
}

.cre-dri-prfl {
    text-align:center;
    max-width:500px;
    margin:auto;
}

.submit-btn {
    margin:25px
}

.add-edit-driver {
    text-align:center;
}

div>form>div.row {
    margin:15px;
}

.create-driver-form {
    max-width:500px;
    margin:auto;
}

.driver-pic-div {
    max-width: 170px;
    height: auto;
    margin:auto;
}

.submit-btn-box {
    margin:50px;
}

.img-box {
    margin:25px;
}

.driver_id {
    color:red;
}

.blue_email {
    color:blue;
}

.driver-name {
    margin-top: 50px;
}

.driver_info {
    margin-bottom:0;
}

.picture-border, .edit-border {
    margin-top: 50px !important;
}

.create-driver-error {
    color:white;
}