.endAdornment {
    top: -17px;
    float: right;
    position: relative;
}

.zero-padding {
    padding-top: 0 !important;
}

