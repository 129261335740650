.modal-pdf-viewer > canvas {
height: calc(100% - 50px);
width: auto
}

.prev-next-btn-wrapper, .modal-pdf-viewer {
    text-align: center;
}

.prev-next-btn-wrapper > p {
    display: inline-block;
    margin: 0 15px;
}

.submit-cancel-wrapper {
    display: flex;
    justify-content: space-between;
}
