h2{
    color:black;
}

.main-box{
    display:inline-block;
    padding:0 15px;
    text-align:center;
    width:100%;
    min-height:700px;
}

.inner-box{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    text-align:center;
    padding-right:50px
}

.accounts,.create-profile{
    max-width: 500px;
    margin:auto;
}

ul{
    display:inline
}

.account-info{
    display:inline-block
}

.home-screen-message{
    text-align:left
}
.info{
    margin: 10px 0 10px 0;
}

.home-message {
    margin:auto;
    max-width:500px;
    text-align:center;
}

.link2 {
    color:black;
    text-decoration:underline;
}

.link2:hover {
    cursor:pointer;
}