.bol-name {
    max-width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bol-name, .list-number {
    margin: 0;
}

.bol-list-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-center {
    text-align: center;
}

.submit-one-bol, .ship-save {
    margin: 20px !important;
}

.zero-padding {
    padding-top: 0 !important; 
}

@media only screen and (max-width: 1230px) {
    .margin-20 {
        margin: 20px !important;
    }
}
