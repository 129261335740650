.inbound-icon {
    background: linear-gradient(60deg, #26c6da, #00365b);
}

.widget {
    min-width: 300px;
    margin: 0 10px;
}

@media screen and (min-width: 1220px) {
    .widget {
        width: 100%;
        min-width: unset;
        flex-direction: row;
        align-items: stretch;
    }
}