.content-wrapper {
    max-width:500px;
    margin:auto;
    padding:0 20px;
}

.content-wrapper>img {
    width:100%;

}

.all-use-modal {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
    text-align: center;
    margin: auto;
    text-decoration: underline;
    color: red;
    margin-bottom: 15px;
}