.on-time {
    color: green;
    font-size: 20px;
}

.late {
    color: red;
    font-size: 20px;
}

.heading-wrapper {
    position: relative;
    width: 300px;
    margin: auto;
}

.edit-icon {
    position: absolute;
    top: 15px;
    left: 220px;
}

.header {
    margin: 30px auto;
}

.edit-icon:hover, .company-edit-icon:hover {
    cursor: pointer
}

.compnay-heading-wrapper {
    display: flex;
    width: 270px;
    justify-content: space-between;
    margin: auto;
    align-items: center;
}

.company-edit-icon {
    margin-top: 30px;
}

.edit-user-info {
    margin: 0 auto ;
    max-width: 300px;
}

.edit-user-info > div {
    display: block;
    width: 300px;
}

.edit-user-info > div > div {
    width: 100%;
}

.submit-button {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(30 56 233 / 40%) !important;
    background-color: #001933 !important;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    color: rgba(255,255,255,0.5);
}

.block {
    display: block;
}

.link {
    margin-top: 20px;
}

p.link {
    color: #3c4858 !important;
}

.link:hover {
    cursor: pointer;
}

.change-password {
    font-size: 1.063rem !important;
    font-weight: 300;
    line-height: 2.5em !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}