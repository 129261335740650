.login-box {
    min-height:500px;
    margin:auto;
    position:relative;
    top:150px;
    max-width:350px;
    text-align:center;
    padding:10px;
}

.forgot-password {
    font-size: 14px;
    line-height: 1.42857;
    color: #aaaaaa;
    font-weight: 400;
}

.forgot-password:hover {
    color: #aaaaaa;
}

.login-input {
    margin:5px
} 

#pwd {
    margin-bottom:30px
}

.btn.btn-default {
    margin-left: -15px;
}

.faqs {
    min-height:500px;
    text-align: center;
}

.faqs-sidenav {
    width: 210px;
    position: fixed;
    text-align:left;
    left: 10px;
    background: grey;
    overflow-x: hidden;
    padding: 8px 0;
    
}

.faqs-sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    text-decoration:none;
    display: block;
}

.faqs-sidenav a:hover {
    color: #064579;
}

.actual-faqs {
    margin-left: 250px;
    /* font-size: 28px;  */
    padding: 0px 10px;
    text-align:left;
    margin-bottom:50px;
}

.actual-faqs>h3 {
    margin-left:unset;
}

.spacer {
    height:30px;
}
@media screen and (max-width: 570px) {
    .faqs-sidenav {
        display:none;
        position:unset;
        width:0;
    }
    
    .actual-faqs {
        margin:0 25px 50px 25px;
    }
}

.content-wrapper {
    max-width:500px;
    margin:auto;
    padding:0 20px;
}

.content-wrapper>img {
    width:100%;

}


.password-reset {
    font-size: 14px;
    line-height: 1.42857;
    color: #aaaaaa;
    font-weight: 400;
}

.password-reset:hover {
    cursor: pointer;
}