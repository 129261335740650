.wrapper {
    text-align: center;
    min-height: 560px; /*if it is 600px there will be a strange grey bar underneath...*/
    padding-bottom: 50px;
}

@media only screen and (max-width: 320px) {
    .wrapper {
        min-height: 600px;
    }
}