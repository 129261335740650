h5 {
    margin-left: 10px;
}

.margin {
    margin: 0 10px;
}

.content-wrapper {
    text-align: left ;
}

.input-wrapper {
    display: flex;
}

.input-wrapper > div{
    margin: 10px;
    min-width: 300px;
}

.inner-input-wrapper {
    min-width: 50%;
}

.delete:hover {
    cursor: pointer;
}

.hide {
    display: none
}

@media screen and (max-width: 960px) {
    .input-wrapper {
        justify-content: space-around;
        flex-wrap: wrap;
    }
}