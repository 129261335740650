.dashboard-wrapper {
    min-height: 100vh;
    padding: 0 25px;
}

.widget-container {
    width: 100%;
    margin: 0 -10px;
    overflow-x: auto;
    display: inline-flex;
}

.no-underline {
    text-decoration: none;
}

@media screen and (min-width: 1220px) {
    .widget-container {
        display: flex;
        overflow-x: unset;
        width: unset
    }
}