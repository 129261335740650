.bol-list-wrapper {
    text-align: left;
    max-width: 500px;
}

.bol-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.bol-name:hover {
    cursor: pointer;
}
/* todo: put this in App.module.css */
.no-margin-bottom {
    margin-bottom: 0 !important;
}
