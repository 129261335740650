.pure-control-group{
    display:block;
}

.outter-box.form {
    margin:auto;
    margin-top:50px;
    margin-bottom:-50px;

}

div.pure-control-group {
    margin:15px;
}


.col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}


.password, .confirmPassword {
    
}

div.pure-control-group>p {
    margin: -5px;
}

div>input.email {
    margin-bottom:5px;
}

div.checkbox {
    margin-bottom:5px;
    text-align:center;
}

div.col-25 {
    text-align:left;
}


@media (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }

    div.col-25 {
        text-align:center;
    }
}