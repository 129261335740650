*{
	box-sizing:border-box;
	font-family: 'Open Sans', sans-serif;
}
html, body{
	height: 100%;    
	margin:0;
}

footer{
    background-color: #001933;
	display:flex;
    flex-wrap:wrap;
    position: relative;
    padding: 0.5rem 15px;
    width:100%;
    
    font-size: 1rem;
    line-height: 1.75em;
    text-align: center;
}

#body {
    overflow:scroll;/*USE THIS BIT HERE FOR SCROLLTOTOP COMPONENT TO WORK.*/
    overflow-x:hidden; /*THE SCROLLTOP PROPERTY ON THE WINDOW OBJ WILL ONLY WORK WITH CERTAIN OVERFLOW PROPERTIES*/
}
#root {
	height:auto;
}

#footer {
	transition: 0.5s;
}

.main-container{
	/* height:100vh; */
	margin:0;
	transition: margin-left .5s;
}

.link {
    color:white;
    text-decoration:none;
    
}

.link:hover {
	cursor:pointer;
}

canvas {
	border:1px solid black;
	width: 85% !important;
	height:100% !important;
}

.child {
    width:200px;
    height:200px;
    margin:auto;
    color:white;
}

.developer {
    bottom:0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    padding:20px;
    background:#161f2c;
    height:70px;
}

.child-header {
	color:rgba(255,255,255,0.5);
    margin-bottom: 5px;
	margin-top: 22px;
	margin-left:0;
}

.icon-link {
    color:rgba(255,255,255,0.5);
    text-decoration:none;
}


p.icon-label1 {
    margin-bottom:-10px
}

p.icon-label2 {
    margin-bottom:-10px;
    margin-top:-20px;
}

.dev-credit {
    margin-top:5px;
    color:white;
    font-size:small;
    float:left;
}

.owner {
    margin-top:5px;
    color:white;
    font-size:small;
    float:right;
    margin-bottom:0;
    margin-left:50px
}

.fa-copyright {
    margin:0
}
@media screen and (max-width: 420px) {
    .developer {
        height:115px;
    }
}


/* LOADER */

.loader {
    position: absolute;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #658295;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    
    top: 50%;
    left: 50%;
  
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }