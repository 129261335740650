.main-wrapper {
    text-align: center;
}

.hide {
    display: none !important;
}

.label {
    z-index: 100;
    position: absolute;
}

.profile-pic {
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

.add-height {
    height: 300px;
}

.save {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(30 56 233 / 40%) !important;
    background-color: #001933 !important;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    color: rgba(255,255,255,0.5);
}

@media only screen and (max-width: 320px) {
    .upload-wrapper {
        width: 250px !important;
        height: 250px !important;
    }
}