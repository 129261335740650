div.badge{
    margin:15px 15px 0 15px;
    border:1px solid black;
    width:100px;
    height:100px;
    border-radius: 50%;
}

.badge-box {
    margin: 0 20px 20px 20px;
    display:inline-block;
}

/* .badge:hover{
    cursor:pointer;
} */
.badges-container{
    margin:auto;
    text-align:center; 
    height:50%;
}

.badge a{
    color:black;
    text-decoration:none;
}

.welcome-header{
    text-align: left;
    padding: 20px;
}

.welcome-message {
    text-align:right;
    padding: 15px;
    max-width: 600px;
    margin: auto;
    color: inherit;
}

.signLgn-box {
    height:50%; 
}



.about-box, .signLgn-links {
    margin:25px;
    text-align:center;
}


.driver {
    background-image:url('https://connex-imgs.s3.amazonaws.com/connexapp-images/driver.jpg') ;
    background-repeat: no-repeat;
    background-size: cover;
}

.carrier {
    background-image:url('https://connex-imgs.s3.amazonaws.com/connexapp-images/carrier2.jpg') ;
    background-repeat: no-repeat;
    background-size: cover;
}

.shipper {
    background-image:url('https://connex-imgs.s3.amazonaws.com/connexapp-images/shipper.jpg') ;
    background-repeat: no-repeat;
    background-size: cover;
}

.welcome-box {
    width:100%;
    padding: 15px;
}

.login-link,.sign-up-link{
    display:block;
    font-size:20px;
}

.login-button, .all-buttons {
    color:white;
    background:#001933;
    border-color:white;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    border-radius:3px;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #166fac),color-stop(100%, #002246));
    margin-bottom:25px;
    font-size: 1em;
}

.member-button {
    color:white;
    background:#001933;
    border-color:white;
    background-image: linear-gradient(
        #00365b 0%, #001933 100%
    );
    border-radius:3px;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #166fac),color-stop(100%, #002246));
    margin-bottom:25px;
    font-size: 1em;
}