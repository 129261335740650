.outter-wrapper {
    min-height: 500px;
    text-align: center;
}

.header-box {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: 50px;
    padding: 8px 48px 8px 35px;;
}

.remove-header {
    text-align: right;
    max-width: 20px;
    padding-right: 25px;
}

.remove-header:hover {
    cursor: pointer;
}

.list-item > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

div.MuiListItemText-root > span.MuiTypography-root .MuiListItemText-primary {
    max-width: 150px !important;
}

.hide {
    display: none;
}

