h2{
    color:white;
}

.header-container {
    background-color:#00365b;
    width:100%;
    margin-bottom:20px;
    padding: 5px; 
    top:0;
    box-shadow:0 0 20px 0 #888888;
    height:auto;
    text-align: center;
    padding:10px;
}

.header {
   
    max-width: 300px;
    height: auto;
}

.header-wrapper {
    max-width:400px;
    height:auto;
    display:inline-block;
}

.header-wrapper > a {
    margin-right: 35px;
}

.logout {
    position:absolute;
    margin-top:-2px;
    right:26px;
    top:6px;
    margin-right:-6px;
}

.title{
    margin-left:5px;
}

.driver-img {
   width: 30px;
   height: 30px;
    border-radius:50%;
}

.avatar-box {
    width:30px;
    height:30px;
    position:absolute;
    display:initial;
    right:20px;
    top:2px;
    margin-top:30px;
}

.connex-logo {
    z-index:50;
    width: 55%;
    height: auto;
}

.hamburger {
    color: white;
    font-size: 30px;
    margin-bottom: 7px;
    margin-top: -10px;
    margin-left: 21px;
}

.hamburger-menu {
    float:left;
}

.no-marg {
    margin:0;
}

#other-nav {
    width: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    background-color:#00365b;
    left: 0px;
    transition: 0.5s;
    box-shadow: 0 0 20px 0 #888888;
}

.avatar-pic {
    width:30px;
    height:30px;
    float:right;
    margin:7px 15px;
}

.fix-nav-hamburger-menu {
    float:left;
    margin-bottom: 5px;
}

.logout2 {
    float: right;
    margin-right: 50px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-left:10px;
}

.connex-lil-logo {
    max-width: 140px;
    height: auto;
    float: right;
    margin-top: 2px;
    margin-right:15px;
    margin-bottom:2px;
}