.side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}


.side-nav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: rgba(255,255,255,0.5);
    display: block;
    transition: 0.3s;
    margin-top:0;
    margin-bottom:0;
}

.side-nav a:hover {
    color: #f1f1f1;
    cursor:pointer;
}

.side-nav .close-btn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.close-btn {
    color: rgba(255,255,255,0.5);
    margin: 15px 15px 0 0;
}

.close-btn:hover {
    color: #f1f1f1;
}

@media screen and (max-height: 450px) {
    .side-nav {padding-top: 15px;}
    .side-nav a {font-size: 18px;}
}
